import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CHeaderToggler,
  CHeaderNav,
  CNavLink,
  CNavItem, CContainer, CHeaderDivider, CImage, CButton
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

import { uiActions } from '../store/actions/uiActions';
import TheHeaderDropdown from './TheHeaderDropdown'
import TheBreadcrumb from './TheBreadcrumb'

import { useTranslation } from 'react-i18next';

const TheHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const sidebarShow = useSelector(state => state.ui.dashboard.sidebarShow)
  const setSidebarShow = (value) => dispatch(uiActions.setSidebarShow(value))
  const birth_info = useSelector(state => state.astro.charts.birthinfo)
  const user = useSelector(state => state.auth.user)
  const preferences = useSelector(state => state.preferences)
  const ready = birth_info && birth_info.complete === true
  const billingStatus = useSelector(state => state.billing.billingStatus);

  const toggleSidebar = () => {
    setSidebarShow(!sidebarShow)
  }

  return (
    <CHeader position="sticky">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1 my-auto me-2"
          onClick={() => { toggleSidebar() }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>

        <CHeaderNav className='d-lg-none me-auto'>
          {ready && <TheBreadcrumb />}
        </CHeaderNav>

        <CHeaderNav className="d-none d-lg-flex me-auto">
          {user.isRootAdmin && preferences.adminMode === 'on' &&
            <CNavItem className='me-2'>
              <CNavLink component={NavLink} className='bg-danger text-white font-weight-bold' to={ready ? "/admin/root" : ""}>{t('containers.theHeader.nav.admin')}</CNavLink>
            </CNavItem>
          }
          {user.isCMSAdmin && preferences.adminMode === 'on' &&
            <>
              <CNavItem className='me-2'>
                <CNavLink component={NavLink} className='border-danger font-weight-bold' to={ready ? "/admin/cms/backend" : ""}>{t('containers.theHeader.nav.backend')}</CNavLink>
              </CNavItem>
              <CNavItem className='me-2'>
                <CNavLink component={NavLink} className='border-danger font-weight-bold' to={ready ? "/admin/cms/frontend" : ""}>{t('containers.theHeader.nav.frontend')}</CNavLink>
              </CNavItem>
            </>
          }
          <CNavItem component={NavLink} to='/dashboard'>
            {t('route.dashboard')}
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <TheHeaderDropdown />
        </CHeaderNav>

      </CContainer>
      <CHeaderDivider className='d-none d-lg-flex' />
      <CContainer fluid className='d-none d-lg-flex'>
        <CHeaderNav className='d-lg-none'>
          <CNavItem>
            <CImage
              src='/images/banner-light.png'
              alt="logo"
              height="32"
            />
          </CNavItem>
        </CHeaderNav>
        {ready && <TheBreadcrumb />}

        <CHeaderNav className="ms-auto px-2">
          {billingStatus.subscription?.paymentDetails?.planType ?
            <CButton size='sm' color='info' variant='ghost' component={NavLink} to='/billing/status'>
            {billingStatus.subscription?.paymentDetails?.planType}
            </CButton>
            :
            <CButton size='sm' color='warning' variant='ghost' component={NavLink} to='/billing/status'>
              FREE
            </CButton>
          }
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default TheHeader
